import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Box,
  Chip,
  Tooltip,
  Checkbox,
  Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useState } from 'react';
import SimpleBackdrop from '../Common/SimpleBackdrop';
import { getProduct } from '../../services/melhorGestao/products';
import SimpleImage from '../Common/SimpleImage';
import DialogProductImage from './Dialogs/DialogProductImages';
import StockBlock from './elements/StockBlock';
import LimitTypography from '../Common/LimitTypography';
import PriceBlock from './elements/PriceBlock';
import DevelopedBy from '../Common/DevelopedBy';

const useStyles = makeStyles((theme) => ({
  smallCell: {
    width: 60,
  },
  mediumCell: {
    width: 85,
  },
  priceCell: {
    width: 160,
  },
  platformsCell: {
    width: 200,
  },
  centerCellVertical: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '75%',
  },
  typographyCursor: {
    cursor: 'pointer',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowSelected: {
    backgroundColor: theme.palette.grey[300],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  totalSales: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function TableProducts({
  companiesConfigurations,
  filterByCompany,
  productsList,
  countProducts,
  selectProduct,
  selectedProduct,
  handleChangePage,
  page,
  limit,
  checkedProducts,
  setCheckedProducts,
  loadingProductsList,
}) {
  const classes = useStyles();

  const handleCheckProduct = ({ productId }) => {
    let newCheckedProducts = checkedProducts;
    newCheckedProducts = newCheckedProducts.filter(
      (checkedProductId) => checkedProductId !== productId,
    );
    newCheckedProducts.push(productId);

    return setCheckedProducts(newCheckedProducts);
  };

  const handleUncheckProduct = ({ productId }) => {
    let newCheckedProducts = checkedProducts;
    newCheckedProducts = newCheckedProducts.filter(
      (checkedProductId) => checkedProductId !== productId,
    );

    return setCheckedProducts(newCheckedProducts);
  };

  const isCheckedProduct = ({ productId }) => {
    if (checkedProducts.filter((checkedProductId) => checkedProductId === productId)?.length > 0) {
      return true;
    }
    return false;
  };

  const handleUncheckAll = () => setCheckedProducts([]);

  const handleCheckAll = () => {
    const productsIds = productsList.map((product) => product.productId);
    return setCheckedProducts(productsIds);
  };

  const handleSelectProduct = async (productId) => {
    const product = await getProduct(productId);
    selectProduct(product);
  };

  const [selectProductImage, setSelectProductImage] = useState(null);

  const handleOpenDialogProductImages = (product) => {
    if (product.images && product.images.length) {
      setSelectProductImage(product);
    }
  };

  const handleCloseDialogProductImages = () => setSelectProductImage(null);

  return (
    <Box position="relative" marginBottom={1}>
      <SimpleBackdrop loading={loadingProductsList} absolutePosition />

      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.smallCell}>
                  <Tooltip
                    title={
                      <Typography align="center">
                        {checkedProducts?.length > 0
                          ? 'Desselecionar todos os itens'
                          : 'Selecionar todos os itens desta página'}
                      </Typography>
                    }
                  >
                    <Checkbox
                      onClick={checkedProducts?.length > 0 ? handleUncheckAll : handleCheckAll}
                      checked={checkedProducts?.length > 0}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="center" className={classes.smallCell}>
                  SKU
                </TableCell>
                <TableCell align="center" className={classes.mediumCell}>
                  Imagem
                </TableCell>
                <TableCell>Nome</TableCell>
                <TableCell className={classes.smallCell}>Estoque</TableCell>
                <TableCell className={classes.priceCell}>Preço</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsList?.length > 0
                ? productsList.map((product) => (
                    <TableRow
                      key={product.productId}
                      className={
                        selectedProduct && selectedProduct.productId === product.productId
                          ? classes.tableRowSelected
                          : classes.tableRow
                      }
                    >
                      <TableCell align="center">
                        <Checkbox
                          checked={isCheckedProduct({ productId: product.productId })}
                          onClick={() => {
                            if (isCheckedProduct({ productId: product.productId })) {
                              handleUncheckProduct({ productId: product.productId });
                            } else {
                              handleCheckProduct({ productId: product.productId });
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">{product.customSku || product.productId}</TableCell>
                      {product.images && product.images?.length > 0 ? (
                        <TableCell onClick={() => handleOpenDialogProductImages(product)}>
                          <>
                            <Grid align="center">
                              <SimpleImage
                                src={product.images[0].src}
                                height={75}
                                width={75}
                                alt={product.name}
                                className={classes.img}
                              />
                            </Grid>
                            <Tooltip
                              title={
                                <Typography align="center">
                                  Valor referente ao total de pedidos do produto. Não é o total
                                  (unitário) de produtos vendidos.
                                </Typography>
                              }
                            >
                              <Typography variant="caption" className={classes.totalSales}>
                                {product.totalSales === 1 ? '1 venda' : null}
                                {product.totalSales > 1 ? `${product.totalSales} vendas` : null}
                              </Typography>
                            </Tooltip>
                          </>
                        </TableCell>
                      ) : (
                        <TableCell align="center">
                          <SimpleImage
                            src="/assets/imgs/no_image.png"
                            imgHeight={30}
                            imgWidth={30}
                            alt={product.name}
                          />
                        </TableCell>
                      )}
                      <TableCell onClick={() => handleSelectProduct(product.productId)}>
                        <Grid container direction="column">
                          <Grid item>
                            <LimitTypography
                              style={{ color: 'grey' }}
                              text={product.name}
                              maxLength={60}
                              variant="caption"
                            />
                          </Grid>
                          <Grid item>
                            <LimitTypography
                              text={product.technicalName}
                              maxLength={60}
                              variant="caption"
                            />
                          </Grid>
                          <Grid item>
                            <Typography style={{ fontSize: 10, color: 'grey' }}>
                              {product.localization}
                            </Typography>
                          </Grid>
                        </Grid>
                        {product.composition ? (
                          <Chip size="small" label="KIT" color="secondary" />
                        ) : null}
                      </TableCell>
                      <TableCell>
                        <StockBlock
                          product={product}
                          filterByCompany={filterByCompany}
                          companiesConfigurations={companiesConfigurations}
                        />
                      </TableCell>
                      <TableCell>
                        <PriceBlock product={product} />
                      </TableCell>
                    </TableRow>
                  ))
                : null}

              {page !== 1 && productsList?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography
                          onClick={(event) => handleChangePage(event, 1)}
                          className={classes.typographyCursor}
                        >
                          Nenhum produto nesta página, clique aqui para voltar a página.
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}

              {page === 1 && productsList?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography>
                          {loadingProductsList ? 'Carregando' : 'Nenhum produto para exibir'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={7}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <Pagination
                        count={Math.ceil(countProducts / limit)}
                        page={page}
                        color="primary"
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
      {selectProductImage ? (
        <DialogProductImage
          selectedProduct={selectProductImage}
          openDialogProductImages={!!selectProductImage}
          handleCloseDialogProductImages={handleCloseDialogProductImages}
        />
      ) : null}
      <DevelopedBy />
    </Box>
  );
}

export default memo(TableProducts);
